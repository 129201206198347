<template>
    <div class="wrapper">
        <Header :code="projectCode"></Header>
        <div class="page-title icon" v-if="viewForm.status !== 4"><Icon type="md-sad" /></div>
        <div class="page-title icon" v-else><Icon type="md-happy" /></div>
        <div class="content">
            <div class="main-content">

                <div class="prompt">
                    <div class="headline">温馨提示：</div>
                    <div class="section" v-if="viewForm.status===0">
                        抱歉，{{ viewForm.companyName }}{{ viewForm.name }}{{ status }}，请留意其它招聘项目，谢谢！
                    </div>
                    <div class="section" v-if="viewForm.status===2 || viewForm.status===3">
                        抱歉你来晚了，{{ viewForm.companyName }}{{ viewForm.name }}{{ status }}，请留意其它招聘项目，谢谢！
                    </div>
                    <div class="section" v-if="viewForm.status===4">
                        恭喜你，登录成功！请点击右上角下拉菜单查看报名记录。
                    </div>
                    <div class="section" v-if="viewForm.status===5">
                        您的链接地址有误，请核实后重新打开！
                    </div>
                </div>
                
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer"
import {getProjectByCode} from "../../api/client-api";

export default {
    components: {
        Header: Header,
        Footer: Footer
    },
    data() {
        return {
            viewForm: {},
            projectCode: null,
            status: '',
        }
    },
    methods: {
        getProject() {
            getProjectByCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.viewForm = res.data.data
                    this.title = this.viewForm.companyName + this.viewForm.name
                    this.banner = this.viewForm.banner
                    if (this.viewForm.status === 2 ){
                        this.status = '已暂停'
                    }else if (this.viewForm.status === 3 ){
                        this.status = '已结束'
                    }else if (this.viewForm.status === 0 ){
                        this.status = '未开始'
                    }

                }else if (this.projectCode === null || this.projectCode === ''){
                    this.viewForm = {}
                    this.viewForm.status = 4
                }else {
                    this.viewForm = {}
                    this.viewForm.status = 5
                }
            }).catch((err) => {
                console.log('err===', err)
            })
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
        this.getProject()
    }
};
</script>

<style scoped>

</style>

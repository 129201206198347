import { render, staticRenderFns } from "./projectPrompt.vue?vue&type=template&id=63c41966&scoped=true&"
import script from "./projectPrompt.vue?vue&type=script&lang=js&"
export * from "./projectPrompt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c41966",
  null
  
)

export default component.exports
<template>
  <div class="wrapper has_nav_bar">
      <van-nav-bar title="温馨提示" fixed />
      <div class="content">
          <div class="main-content">
            <div class="prompt">
                <div class="section" v-if="viewForm.status===2 || viewForm.status===3">
                    <van-icon name="warning-o" />
                    <p> 抱歉你来晚了，{{ viewForm.companyName }}{{ viewForm.name }}{{ status }}，请留意其它招聘项目，谢谢！</p>
                </div>
                <div class="section" v-if="viewForm.status===0">
                    <van-icon name="warning-o" />
                <p>抱歉，{{ viewForm.companyName }}{{ viewForm.name }}{{ status }}，请留意其它招聘项目，谢谢！</p>
                </div>
                <div class="section" v-if="viewForm.status===4">
                    <van-icon name="smile-o"  />
                    <p>恭喜你，登录成功！请点击下方我的报名查看报名记录。</p>
                </div>
                <div class="section" v-if="viewForm.status===5">
                    <van-icon name="warning-o" />
                    <p>您的链接地址有误，请核实后重新打开！</p>
                </div>
            </div>
          </div>
      </div>
      <div class="footer">
      </div>
      <div class="tab_bar">
          <van-tabbar>
              <van-tabbar-item name="home" icon="wap-home">招聘主页</van-tabbar-item>
              <van-tabbar-item :badge='messageCounts' name="message" icon="chat" @click="goMessageCenter">消息中心</van-tabbar-item>
              <van-tabbar-item name="application" icon="column" @click="goMyApplication">我的报名</van-tabbar-item>
              <van-tabbar-item name="my" icon="manager" @click="goUserCenter">个人中心</van-tabbar-item>
          </van-tabbar>
      </div>
  </div>
</template>

<script>
import {getCountOfUnreadMessage, getProjectByCode} from "../../api/client-api";

export default {
  data() {
      return {
          messageCounts: "",
          viewForm: {},
          projectCode: null,
          status: '',
          isLogon: null,
      }
  },
  methods: {
      goMessageCenter() {
          this.$router.push({name: 'messageCenter', query: {projectCode: this.projectCode}});
      },
      goMyApplication() {
          this.$router.push({name: 'myApplication', query: {projectCode: this.projectCode}});
      },
      goUserCenter() {
          this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
      },
      getProject() {
          getProjectByCode({code: this.projectCode}).then((res) => {
              if (res.data.data) {
                  this.viewForm = res.data.data
                  if (this.viewForm.status === 2 ){
                      this.status = '已暂停'
                  }else if (this.viewForm.status === 3 ){
                      this.status = '已结束'
                  }else if (this.viewForm.status === 0 ){
                      this.status = '未开始'
                  }

              }else if ((this.projectCode === null || this.projectCode === '') && this.isLogon){
                  this.viewForm = {}
                  this.viewForm.status = 4
              }else {
                  this.viewForm = {}
                  this.viewForm.status = 5
              }
          }).catch((err) => {
              console.log('err===', err)
          })
      },
      countOfUnreadMessage() {
          getCountOfUnreadMessage().then((res) => {
              if (res.data.data !== 0){
                  this.messageCounts = res.data.data + '';
              }
          }).catch((err)=>{
              this.messageCounts = null;
          });
      },
  },
  mounted() {
      let that = this;
      that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
      if (that.isLogon) {
          that.countOfUnreadMessage();
          that._timer = setInterval(function() {
              that.countOfUnreadMessage();
          }, 1000*60);
      }
      this.projectCode = this.$route.query.projectCode;
      this.isLogon = that.isLogon;
      this.getProject()
  }
};
</script>

<style scoped>

</style>
